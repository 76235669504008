* {
  margin: 0;
  padding: 0;
}
:root {
  --main-text: #1e1e21;
  --light-cream: #f9f8f6;
  --light-gray: #dbdad9;
  --light-brown: #7b6f6e;
  --content-width: 90vw;
  --image-width: 40vw;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("./fonts/Lato-Regular.woff2") format("woff2"),
    url("./fonts/Lato-Regular.woff") format("woff"),
    url("./fonts/Lato-Regular.eot") format("eot"),
    url("./fonts/Lato-Regular.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Lato-Light";
  src: url("./fonts/Lato-Light.woff2") format("woff2"),
    url("./fonts/Lato-Light.woff") format("woff"),
    url("./fonts/Lato-Light.eot") format("eot"),
    url("./fonts/Lato-Light.ttf") format("ttf");
  font-weight: 300;
}

/* martel-sans-300 - latin */
@font-face {
  font-family: "Martel";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/martel-sans-v5-latin-ext-300.eot"); /* IE9 Compat Modes */
  src: url("./fonts/martel-sans-v5-latin-ext-300.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/martel-sans-v5-latin-ext-300.woff2") format("woff2"),
    url("./fonts/martel-sans-v5-latin-ext-300.woff") format("woff"),
    url("./fonts/martel-sans-v5-latin-ext-300.ttf") format("truetype");
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
}
body {
  font-family: "Lato-Light";
  font-weight: 300;
  font-size: 1.12rem;
  letter-spacing: 0.035rem;
  line-height: 1.7rem;
  color: var(--main-text);
}
.center-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
a.content-button {
  min-width: 8rem;
  background: var(--main-text);
  height: 3rem;
  border-radius: 2rem;
  line-height: 3rem;
  padding: 0.5rem 2rem 0.5rem 2rem;
  color: var(--light-cream);
  text-transform: uppercase;
  font-size: 0.95rem;
  font-family: "Lato-Regular";
  margin: 2rem auto;
  text-decoration: none;
  text-align: center;
}
#root > div {
  top: 0 !important;
}
a.rectangle {
  border: 2px solid;
  min-width: 8rem;
}
a.circle {
  width: 3rem;
  clip-path: circle(48% at center);
}
a.button {
  font-family: "Lato-Regular";
  margin: 0 0.5rem 0.5rem 0.5rem;
  font-size: 1.2rem;
  line-height: 3rem;
  border-radius: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  color: white;
  background: var(--main-text);
  text-decoration: none;
  vertical-align: middle;
  height: 3rem;
  transition: all 0.5s;
  border-color: var(--main-text);
  box-sizing: border-box;
}
a.button:hover {
  color: var(--main-text);
  background: #dadadf;
}
a.button path {
  fill: white;
}
a.button:hover path {
  fill: var(--main-text);
}
p {
  margin-bottom: 1.25rem;
}
h1 {
  font-size: 3.5rem;
  font-weight: 300;
  font-family: "Lato-Light";
  margin-bottom: 2rem;
  line-height: 4rem;
}
.intro-image {
  width: var(--image-width);
  height: var(--image-width);
  margin: 4rem auto 3rem auto;
  box-sizing: border-box;
  border-radius: var(--image-width);
  overflow: hidden;
}
.quote {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 10rem 2rem 10rem 2rem;
}
h3 {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: "Lato-Regular";
  margin-bottom: 2rem;
  line-height: 3rem;
}

.quote h3 {
  max-width: 700px;
  min-width: 300px;
  font-style: italic;
}
ul {
  padding-left: 2rem;
  margin-bottom: 1.25rem;
}
.last {
  padding-bottom: 1rem !important;
}
.full-frame {
  width: 100%;
  padding: 7rem 4rem 7rem 4rem;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
}
.full-frame h1 {
  max-width: 700px;
  min-width: 300px;
}
.main-content {
  padding: 7rem 4rem 7rem 4rem;

  margin: 0 auto;
}
.content-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  min-width: 300px;
  margin: 0 auto;
}
.column {
  width: 50%;
}
.column:first-child {
  padding-right: 2rem;
}

hr {
  border: none;
  background-color: var(--light-gray);
  height: 1px;
  margin: 3rem 0 3rem 0;
}
.buttons-language {
  margin-bottom: 2rem;
}
.main-image {
  width: 100vw;
  height: 60vw;
  margin: 0 auto;
  background-image: url("./assets/main-image-sml-M.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.back-dark {
  background: var(--main-text);
}
.back-light {
  background: var(--light-cream);
}
@media (max-width: 600px) {
  .main-content {
    padding: 4rem 1rem 4rem 1rem;
  }
  .full-frame {
    padding: 4rem 2rem 4rem 2rem;
  }
  h1 {
    font-size: 2.5rem;
    line-height: 3.2rem;
  }
  :root {
    --image-width: 80vw;
  }
  .main-image {
    background-image: url("./assets/main-image-sml-S.jpg");
  }
  .content-wrapper {
    max-width: 480px;
    flex-direction: column;
  }
  .column {
    width: 90%;
    margin: 0 auto;
  }
  .column:first-child {
    padding-right: 0;
  }
}
@media (min-width: 1000px) {
  .main-image {
    background-image: url("./assets/main-image-sml-L.jpg");
  }
  .content-wrapper {
    max-width: 980px;
  }
}

@media (min-width: 1400px) {
  .content-wrapper {
    max-width: 1280px;
  }
}
